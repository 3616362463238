<template>
  <div class="container-page big">
    <HeaderBlock
      title="Перечень односторонних мер таможенно-тарифного регулирования"
      :selectedIds="selectedIds"
      @create="editCard(0)"
      @clear="clearFilter"
      @export="exportExcel"
      @import="importExcel"
      @editView="editView"
      @bulkEditing="bulkEditing"
    />

    <div class="table-shadow">
      <div class="table-container-base table-container-base--sort js-table-arrow">
        <table>
          <HeaderTable
            :tableHeader="getTableHeaderSingleOdnostoron"
            @changeSorting="changeSorting"
            @changeFilter="changeFilter"
            @changeSelectAll="changeSelectAll"
            ref="headerTable"
          />
          <TableBody :itemsBody="page.items" :isLoading="isLoading">
            <tr v-for="item in page.items" :key="item.id" @dblclick="editCard(item.id)">
              <td class="column-icon">
                <div class="wrap-icon">
                  <IconComponent class="icon--full-svg" @click="editCard(item.id)" name="edit" />
                  <IconComponent class="icon--full-svg delete" @click="deleteCard(item.id)" name="delete-not-fill" />
                </div>
              </td>
              <td class="width-46"><input type="checkbox" v-model="selectedIds" :value="item.id" /></td>
              <td v-if="getVisibleItems.includes(tableIdColumns.year)" class="width-138">{{ item.year }}</td>
              <td v-if="getVisibleItems.includes(tableIdColumns.organName)" class="width-192">{{ item.organ.name }}</td>
              <td v-if="getVisibleItems.includes(tableIdColumns.decisionDate)" class="width-202">
                {{ $momentFormat(item.decisionDate, 'DD.MM.YYYY') }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.decisionNumber)" class="width-192">
                {{ item.decisionNumber }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.tnvedName)" class="width-352">
                {{ item.tnvedAlias }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.tnvedNameBefore)" class="width-192">
                <div v-for="entry in item.singleTtrHistory" :key="entry.id">
                  {{ entry.tnvedBefore?.code ?? entry.tnvedNameBefore ?? '&mdash;' }}
                </div>
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.rateBefore)" class="width-232">
                <div v-for="entry in item.singleTtrHistory" :key="entry.id">
                  {{ entry.rateBefore ?? '&mdash;' }}
                </div>
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.rateAfter)" class="width-232">
                <div v-for="entry in item.singleTtrHistory" :key="entry.id">
                  {{ entry.rateAfter ?? '&mdash;' }}
                </div>
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.applyDate)" class="width-226">
                <div v-for="entry in item.singleTtrHistory" :key="entry.id">
                  {{ entry.applyDateFrom ? `с ${$momentFormat(entry.applyDateFrom, 'DD.MM.YYYY')}` : '' }}
                  {{ entry.applyDateFrom && entry.applyDateTo ? '-' : '' }}
                  {{ entry.applyDateTo ? `по ${$momentFormat(entry.applyDateTo, 'DD.MM.YYYY')}` : '' }}
                </div>
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.comment)" class="width-232">{{ item.comment }}</td>
              <td v-if="getVisibleItems.includes(tableIdColumns.industryNames)" class="width-192">
                {{ item.industryNames?.join('; ') }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.prolongation)" class="width-192">
                {{ item.prolongation }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.isActive)" class="width-192">
                {{ item.isActive ? 'действ.' : 'не действ.' }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.moveDirection)" class="width-240">
                {{ item.moveDirection?.name }}
              </td>
            </tr>
          </TableBody>
        </table>
      </div>
    </div>

    <PaginationRedesigned
      v-if="page.pagesTotal > 1 && !isLoading"
      :page-count="page.pagesTotal"
      :current-page="page.pageNumber"
      @change="onPageChange($event, false)"
      @more="onPageChange($event, true)"
    />

    <ModalComponent
      v-model="importModal"
      title="Импорт файла"
      @close="closeModals('importModal')"
      headType="redesigned"
      bodyType="redesigned"
      @update:modelValue="closeModals('importModal')"
    >
      <ImportModal @doimport="doImport" @close="closeModals('importModal')" ref="importModal" />
    </ModalComponent>

    <ModalComponent
      v-model="importDataModal"
      :width="1000"
      content-class="modal--new"
      title="Импорт файла"
      @close="closeModals('importDataModal')"
      headType="redesigned"
      bodyType="redesigned"
    >
      <SingleTtrImportComponent :import-data="importData" @close="closeAndReload" :isSecond="true" :nn="nn" />
    </ModalComponent>

    <ModalComponent
      v-model="bulkEditingModal"
      content-class="modal--new"
      title="Массовое редактирование записей"
      headType="redesigned"
      bodyType="redesigned"
      @close="closeModals('bulkEditingModal')"
      @update:modelValue="closeModals('bulkEditingModal')"
    >
      <ContentPopupBulkEditing
        :selectedItems="selectedIds"
        :statusOptions="getStatusOptions"
        @closePopup="closeModals('bulkEditingModal')"
        @save="bulkEditingChange"
        ref="bulkEditingModal"
      />
    </ModalComponent>

    <ModalComponent
      v-model="editViewModal"
      content-class="modal--new"
      title="Настройка вида отображения"
      headType="redesigned"
      bodyType="redesigned"
      @close="closeModals('editViewModal')"
      @update:modelValue="closeModals('editViewModal')"
    >
      <ContentPopupEditView
        :listCheckbox="getTableHeaderSingleOdnostoron"
        @save="changeColumnTable"
        @closePopup="closeModals('editViewModal')"
        ref="editViewModal"
      />
    </ModalComponent>

    <!-- статус Массового редактирования и Настройка вида отображения -->
    <ModalInfo
      v-model="isOpenModalInfo"
      @closeModal="closeModalInfo"
      :titleModal="titleModal"
      :textModal="textModal"
      :modalIconName="modalIconName"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';

  import ModalInfo from '@/common/components/redesigned/ModalInfo.vue';
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned';
  import TableBody from '@/common/components/redesigned/TableBody';
  import Constants from '@/common/constants';
  import modalInfo from '@/common/mixins/modalInfo';
  import removeEmptyValuesFromObject from '@/common/mixins/removeEmptyValuesFromObject';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent';

  import ImportModal from '@/components/modals/ImportModal';
  import ModalComponent from '@/components/modals/ModalComponent';

  import SingleTtrImportComponent from '@/modules/measure/components/SingleTtrImportComponent';

  import Api from '../api/singlettr';
  import ContentPopupBulkEditing from '../components/ContentPopupBulkEditing';
  import ContentPopupEditView from '../components/ContentPopupEditView';
  import HeaderBlock from '../components/HeaderBlock';
  import HeaderTable from '../components/singleComponents/HeaderTable';
  import duplicateMethods from '../mixins/duplicateMethods';
  import {
    SINGLE_TTR_REGISTRY_ACTIONS_NAME,
    SINGLE_TTR_REGISTRY_GETTERS_NAME,
    SINGLE_TTR_REGISTRY_MUTATIONS_NAME,
  } from '../store/singleTtrRegistry/name.js';
  import { MODAL_INFO_MESSAGES, TABLE_ID_SINGLE } from '../utils/constants';

  export default {
    name: 'SingleTtrRegistryOdnostoron',
    components: {
      IconComponent,
      ModalComponent,
      ImportModal,
      SingleTtrImportComponent,
      ModalInfo,
      PaginationRedesigned,
      ContentPopupBulkEditing,
      ContentPopupEditView,
      HeaderBlock,
      TableBody,
      HeaderTable,
    },
    mixins: [security, removeEmptyValuesFromObject, modalInfo, duplicateMethods],
    data() {
      return {
        page: {},
        isSecond: true,
        isLoading: false,
        nn: 1,
        selectedIds: [],
        importModal: false,
        importData: {},
        importDataModal: false,
        bulkEditingModal: false,
        editViewModal: false,
        importInProgress: false,
        filter: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
          isSecond: true,
          sortOrder: 0,
          sortColumn: 0,
        },
        tableIdColumns: TABLE_ID_SINGLE,
      };
    },
    computed: {
      ...mapGetters({
        getTableHeaderSingleOdnostoron: SINGLE_TTR_REGISTRY_GETTERS_NAME.getTableHeaderSingleOdnostoron,
      }),

      getVisibleItems() {
        return this.getTableHeaderSingleOdnostoron.filter((i) => i.visible).map((i) => i.id);
      },

      getFilterData() {
        const dataFilter = this.objectFormatter(this.filter);
        if (this.filter.isActive) {
          dataFilter.isActive = this.filter.isActive == 1;
        }
        return dataFilter;
      },
    },
    created() {
      this.request.isSecond = this.isSecond;

      this.checkPermissionAndRedirect(Constants.Permissions.MeasureSingleTtriew);
      this.loadDictionaries();
      this.loadPage();
    },
    methods: {
      ...mapActions({
        changeParametersMulti: SINGLE_TTR_REGISTRY_ACTIONS_NAME.changeParametersMulti,
      }),

      ...mapMutations({
        updateListTableHeaderSingleOdnostoron: SINGLE_TTR_REGISTRY_MUTATIONS_NAME.updateListTableHeaderSingleOdnostoron,
      }),

      loadDictionaries() {
        this.getNSIMeasureOrganProtectedMarket();
        this.getNSIIndustry();
        this.getNSIMoveDirections();
      },

      loadPage(append) {
        this.isLoading = !append;
        Api.search({
          ...this.request,
          ...this.getFilterData,
        })
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
          .catch((error) => {
            console.error('this.loadPage:', error);
          });
      },

      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },

      editCard(id) {
        this.$router.push({ name: 'SingleTtrOdnostoronCard', params: { id: id, action: 'edit' } });
      },

      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            Api.delete(id)
              .then((response) => {
                console.log(response);
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена.',
                  'error',
                );
              });
          }
        });
      },

      exportExcel() {
        Api.exportExcel({ ...this.getFilterData, isSecond: this.isSecond });
      },

      importExcel() {
        this.importModal = true;
      },

      doImport(file) {
        this.closeModals('importModal');
        this.importInProgress = true;
        Api.uploadFile(file, true)
          .then((response) => {
            this.importInProgress = false;
            this.importData = response.data;
            this.importDataModal = true;
            this.nn++;
          })
          .catch(() => {
            Constants.alert.fire('Импорт', 'Ошибка при импорте данных.', 'error');
            this.importInProgress = false;
          });
      },

      closeAndReload() {
        this.closeModals('importDataModal');
        this.loadPage();
      },

      clearSelection() {
        this.selectedIds = [];
      },

      closeModals(type) {
        this[type] = false;
        if (this.$refs[type]) {
          this.$refs[type].reset();
        }
      },

      async bulkEditingChange(data) {
        const res = await this.changeParametersMulti({
          ...data,
          ids: this.selectedIds,
        });
        this.closeModals('bulkEditingModal');
        this.titleModal = MODAL_INFO_MESSAGES.SAVE_TITLE;
        if (res.success) {
          this.textModal = MODAL_INFO_MESSAGES.SAVE_TEXT;
          this.modalIconName = Constants.MODAL_INFO_ICON.ACCEPT;
          this.isOpenModalInfo = true;
          this.loadPage();
        } else {
          this.textModal = MODAL_INFO_MESSAGES.ERROR_TEXT;
          this.modalIconName = Constants.MODAL_INFO_ICON.ERROR;
          this.isOpenModalInfo = true;
        }
      },

      bulkEditing() {
        this.bulkEditingModal = true;
      },

      editView() {
        this.editViewModal = true;
      },

      changeSorting(sort) {
        const { sortColumn, sortOrder } = sort;
        this.request.sortOrder = sortOrder;
        this.request.sortColumn = sortColumn;
        this.loadPage();
      },

      changeFilter(filter) {
        this.request.pageNumber = 1;
        this.filter = filter;
        this.loadPage();
      },

      clearFilter() {
        this.filter = {};
        this.$refs.headerTable.clearFilter();
        this.loadPage();
      },

      changeSelectAll(value) {
        if (value) {
          this.selectedIds = [];
          this.selectedIds.push(...this.page.items.map((x) => x.id));
        } else {
          this.clearSelection();
        }
      },

      changeColumnTable(array) {
        this.updateListTableHeaderSingleOdnostoron(array);
        this.titleModal = MODAL_INFO_MESSAGES.SAVE_TITLE;
        this.textModal = MODAL_INFO_MESSAGES.SAVE_TEXT;
        this.modalIconName = Constants.MODAL_INFO_ICON.ACCEPT;
        this.closeModals('editViewModal');
        this.isOpenModalInfo = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/table/table-base.scss';
</style>
